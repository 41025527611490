<template>
	<div class="login">
	</div>
</template>

<script>
export default {
	data () {
		return {
		}
	},

	mounted(){
	},

	methods: {
		// 测试用
		// onceshi(){
		// 	this.$service.get(this.$api.getWxpublicArt,'', headers, (res)=> {
		// 		console.log(123)
		// 		if(res.code == '200'){

		// 		}
		// 	})
		// },
	}
}
</script>

<style lang="scss" scoped>
.login{
	
}
</style>
